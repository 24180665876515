<template>
  <div class="user-email-address-container mb-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <!--EMAIL-->
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.contacts.email')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.email"
            :rules="emailRules"
            :placeholder="`${$t('profile.contacts.email')}`"
            required
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <!--PASSWORD-->
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.password')} *`" />
          <v-text-field
            class="mt-2"
            color="secondary"
            v-model="userData.password"
            :placeholder="`${$t('profile.password')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <!--Secondary EMAIL-->
        <v-col cols="12" md="6" v-if="hasCard">
          <label v-html="`${$t('profile.contacts.secondaryEmail')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.contact.email2"
            :rules="secondaryEmailRules"
            :placeholder="`${$t('profile.contacts.secondaryEmailInfo')}`"
            outlined
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <!--IVA-->
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.contacts.iva')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.person.vatCode"
            :rules="ivaRules"
            placeholder="IT"
            :required="!userData.person.vatCode && !userData.person.fiscalCode"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.person.vatCode = userData.person.vatCode.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <!-- Fiscal Code -->
        <v-col cols="12" md="6">
          <label v-html="`${$t('profile.contacts.fiscalCodeIfnoIVA')}`" />
          <v-text-field
            class="mt-2"
            v-model="userData.person.fiscalCode"
            :rules="fiscalCodeRules"
            :placeholder="`${$t('profile.contacts.fiscalCode')}`"
            :required="!userData.person.vatCode && !userData.person.fiscalCode"
            outlined
            :disabled="isFieldDisabled"
            @input="
              val =>
                (userData.person.fiscalCode = userData.person.fiscalCode.toUpperCase())
            "
          ></v-text-field>
        </v-col>
        <!--CLIENT CODE-->
        <v-col cols="12" md="6" v-if="hasCard">
          <label v-html="`${$t('profile.contacts.codInt')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.codInt"
            :placeholder="`${$t('profile.contacts.codInt')}`"
            required
            :rules="[requiredRules()]"
            outlined
            :disabled="isFieldDisabled"
            @input="val => (userData.codInt = userData.codInt.toUpperCase())"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import {
  passwordRule,
  isPivaabbondanza,
  isFiscalCode
} from "@/customValidationRules";
import { isEmail, requiredValue } from "~/validator/validationRules";
import UserService from "~/service/userService";

export default {
  name: "UserEmailAddress",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true },
    hasCard: { type: Boolean, default: false }
  },
  components: {},
  data() {
    return {
      isValid: false,
      showPassword: false,
      passwordConfirm: null,
      disclaimerList: null,
      response: {},
      disclaimerOptions: {
        color: "primary"
      },
      passwordRules: [requiredValue("Digitare la password"), passwordRule()],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      secondaryEmailRules: [isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.userData.email || "Le e-mail non coincidono"
      ],
      ivaRules: [isPivaabbondanza()],
      fiscalCodeRules: [requiredValue(), isFiscalCode()],
      requiredRules: requiredValue
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    },
    fiscalCodeRequired() {
      return false;
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", true, true);
    },
    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      let res = await UserService.getRegistrationDisclaimerList(
        registrationModuleTypeId,
        storeId
      );
      const disclaimers = res.registrationDisclaimers;
      this.disclaimerList = disclaimers.sort((a, b) => a.priority - b.priority);
    },
    disclaimerChanged(registrationModules) {
      this.registrationModules = registrationModules;
      this.userData.registrationModule = this.registrationModules;
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    checkVat(newValue) {
      if (newValue.trim() === "") {
        this.fiscalCodeRules = [requiredValue(), isFiscalCode()];
      } else {
        this.fiscalCodeRules = [isFiscalCode()];
      }
    }
  },

  mounted() {
    this.loadDisclaimer();
    this.$watch("userData.person.vatCode", this.checkVat);
  }
};
</script>
