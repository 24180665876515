<template>
  <div class="mb-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row align="center">
        <!-- COUNTRY -->
        <!-- ENABLED ONLY ON REGISTRATION WITHOUT CARD-->
        <!-- <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.country')}`" />
          <v-select
            :placeholder="`${$t('profile.address.country')}`"
            v-model="userData.billingAddress.country"
            :items="countries"
            return-object
            item-text="name"
            item-value="name"
            required
            outlined
            dense
          />
        </v-col> -->
        <!-- ADDRESS1 -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.address')} * **`" />
          <v-text-field
            :placeholder="`${$t('profile.address.address')} * **`"
            v-model="userData.billingAddress.address1"
            outlined
            dense
            disabled
            @change="validate"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" />
        <!-- POSTAL CODE -->
        <v-col cols="12" sm="3">
          <label v-html="`${$t('profile.address.postalcode')} * **`" />
          <v-text-field
            :placeholder="`${$t('profile.address.postalcode')} * **`"
            v-model="userData.billingAddress.postalcode"
            required
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <!-- PROVINCE -->
        <v-col cols="12" sm="3">
          <label v-html="`${$t('profile.address.province')} * **`" />
          <v-text-field
            :id="'id_' + Math.random()"
            :placeholder="`${$t('profile.address.province')} * **`"
            v-model="userData.billingAddress.province"
            return-object
            item-text="name"
            item-value="name"
            outlined
            disabled
            dense
          ></v-text-field>
        </v-col>
        <!-- CITY -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.address.city')} * **`" />
          <v-text-field
            :placeholder="`${$t('profile.address.city')} * **`"
            v-model="userData.billingAddress.city"
            outlined
            dense
            @change="validate"
            disabled
          ></v-text-field>
        </v-col>
        <!-- ADDRESS NUMBER -->
        <!-- <v-col cols="12" sm="2">
          <label v-html="`${$t('profile.address.addressNumber')} *`" />
          <v-text-field
            :placeholder="`${$t('profile.address.addressNumber')} *`"
            v-model="userData.billingAddress.addressNumber"
            :rules="[requiredRules()]"
            outlined
            dense
            @change="validate"
          ></v-text-field>
        </v-col> -->
        <!-- HOME PHONE -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.homePhone')}`" />
          <v-text-field
            :placeholder="`${$t('profile.contacts.homePhone')}`"
            v-model="userData.contact.workPhone"
            outlined
            dense
            @change="validate"
          ></v-text-field>
        </v-col>
        <!-- MOBILE PHONE -->
        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.mobilePhone')}`" />
          <v-text-field
            :placeholder="`${$t('profile.contacts.mobilePhone')}`"
            v-model="userData.phone"
            outlined
            dense
            @change="validate"
          ></v-text-field>
        </v-col>
        <!-- PREFFERED MARKET-->
        <!-- <v-col cols="12" sm="6">
          <label v-html="$t('profile.contacts.prefferedMarket')" />
          <v-select
            v-model="userData.defaultStoreAddressId"
            :placeholder="$t('profile.contacts.prefferedMarket')"
            return-object
            :items="warehouses"
            item-value="warehouseId"
            item-text="name"
            outlined
            dense
          ></v-select>
        </v-col> -->

        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.emailCertified')}`" />
          <v-text-field
            :placeholder="`${$t('profile.contacts.emailCertified')}`"
            v-model="userData.person.emailCertified"
            outlined
            dense
            @change="validate"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <label v-html="`${$t('profile.contacts.vatSdiCode')}`" />
          <v-text-field
            :placeholder="`${$t('profile.contacts.vatSdiCode')}`"
            v-model="userData.person.vatSdiCode"
            outlined
            dense
            @change="validate"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { requiredValue, isMobilePhone } from "~/validator/validationRules";
import addressService from "~/service/addressService";
//import aliRegistrationService from "@/service/abbondanzaRegistrationService";

export default {
  name: "UserEmailAddress",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    supportData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    response: { type: Object }
  },
  data() {
    return {
      isValid: false,
      warehouses: null,
      countries: null,
      provinces: null,
      bornCountry: null,
      requiredRules: requiredValue,
      mobilePhoneRules: [isMobilePhone()]
    };
  },
  methods: {
    postalCodeRules(v) {
      if (!v) return false;
      if (this.supportData.country) {
        let r =
          this.supportData.country.iso === "CH"
            ? new RegExp(/^[1-9][0-9][0-9][0-9]$/)
            : new RegExp(/^[0-9]{5}$/);
        return (
          !v ||
          r.test(v) ||
          "Il codice postale non è valido per il paese selezionato"
        );
      }
      return false;
    },
    /* changeProvince(province) {
      this.userData.billingAddress.province = province;
    },*/
    validate() {
      let _this = this;
      setTimeout(function() {
        if (_this.$refs.form) {
          _this.valid = _this.$refs.form.validate();
          if (_this.isProfileForm) {
            _this.$emit("validate", _this.valid);
          }
        }
      }, 200);
    },
    async findWarehouse() {
      const res = await addressService.findWarehouse();
      this.warehouses = res.warehouses;
    },
    async loadCountriesList() {
      //this.countries = await aliRegistrationService.getCountries();
    },
    async loadProvinceList() {
      //this.provinces = await aliRegistrationService.provinceList();
    },
    /*selectCountry(country) {
      let _this = this;
      _this.userData.ResCountry = country.Name;
      this.resetProvince();
    },*/
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", this.userData, this.supportData);
    }
  },
  created() {
    this.findWarehouse();
    this.loadCountriesList();
    this.loadProvinceList();
    if (!this.userData.billingAddress) {
      this.userData.billingAddress = { country: {} };
    }
    if (!this.userData.contact) {
      this.userData.contact = {};
    }
  }
};
</script>
