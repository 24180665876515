var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-5" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.address.address")} * **`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t("profile.address.address")} * **`,
                      outlined: "",
                      dense: "",
                      disabled: ""
                    },
                    on: { change: _vm.validate },
                    model: {
                      value: _vm.userData.billingAddress.address1,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.billingAddress, "address1", $$v)
                      },
                      expression: "userData.billingAddress.address1"
                    }
                  })
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "12", sm: "6" } }),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "3" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.address.postalcode")} * **`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t(
                        "profile.address.postalcode"
                      )} * **`,
                      required: "",
                      outlined: "",
                      dense: "",
                      disabled: ""
                    },
                    model: {
                      value: _vm.userData.billingAddress.postalcode,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.billingAddress, "postalcode", $$v)
                      },
                      expression: "userData.billingAddress.postalcode"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "3" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.address.province")} * **`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      id: "id_" + Math.random(),
                      placeholder: `${_vm.$t("profile.address.province")} * **`,
                      "return-object": "",
                      "item-text": "name",
                      "item-value": "name",
                      outlined: "",
                      disabled: "",
                      dense: ""
                    },
                    model: {
                      value: _vm.userData.billingAddress.province,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.billingAddress, "province", $$v)
                      },
                      expression: "userData.billingAddress.province"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.address.city")} * **`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t("profile.address.city")} * **`,
                      outlined: "",
                      dense: "",
                      disabled: ""
                    },
                    on: { change: _vm.validate },
                    model: {
                      value: _vm.userData.billingAddress.city,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.billingAddress, "city", $$v)
                      },
                      expression: "userData.billingAddress.city"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.contacts.homePhone")}`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t("profile.contacts.homePhone")}`,
                      outlined: "",
                      dense: ""
                    },
                    on: { change: _vm.validate },
                    model: {
                      value: _vm.userData.contact.workPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.contact, "workPhone", $$v)
                      },
                      expression: "userData.contact.workPhone"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.contacts.mobilePhone")}`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t("profile.contacts.mobilePhone")}`,
                      outlined: "",
                      dense: ""
                    },
                    on: { change: _vm.validate },
                    model: {
                      value: _vm.userData.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "phone", $$v)
                      },
                      expression: "userData.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.contacts.emailCertified")}`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t(
                        "profile.contacts.emailCertified"
                      )}`,
                      outlined: "",
                      dense: ""
                    },
                    on: { change: _vm.validate },
                    model: {
                      value: _vm.userData.person.emailCertified,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.person, "emailCertified", $$v)
                      },
                      expression: "userData.person.emailCertified"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.contacts.vatSdiCode")}`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    attrs: {
                      placeholder: `${_vm.$t("profile.contacts.vatSdiCode")}`,
                      outlined: "",
                      dense: ""
                    },
                    on: { change: _vm.validate },
                    model: {
                      value: _vm.userData.person.vatSdiCode,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.person, "vatSdiCode", $$v)
                      },
                      expression: "userData.person.vatSdiCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }