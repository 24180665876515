var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "registration-container pt-10 px-3" },
    [
      _c("h1", { staticClass: "mb-5" }, [
        _vm._v(_vm._s(_vm.$t("register.card.title")))
      ]),
      _c("p", {
        staticClass: "description",
        domProps: { innerHTML: _vm._s(_vm.$t("register.card.description")) }
      }),
      _vm._v(" " + _vm._s(this.isRegisterWithCard) + " "),
      _c(
        "v-row",
        { staticClass: "justify-md-space-between" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8" } },
            [
              _c("ResponseMessage", {
                attrs: { response: _vm.response.response || {} }
              })
            ],
            1
          ),
          _vm.hasErrorMessageWithCode("1")
            ? _c(
                "v-col",
                { attrs: { cols: "12", md: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "elevation-0",
                      attrs: {
                        color: "primary",
                        block: "",
                        to: {
                          name: "ResetPasswordRequest",
                          path: "/reset-password-request"
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("profile.edit.password")))]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.hasErrorMessageWithCode("2")
            ? _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "elevation-0",
                      attrs: {
                        color: "primary",
                        block: "",
                        to: {
                          name: "RegistrationNoCard",
                          path: "/registration-nocard"
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("login.buttonRegister")))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-card",
            { staticClass: "w-100 pa-2", attrs: { elevation: "0" } },
            [
              _vm.step === 1
                ? _c("UserData", {
                    attrs: {
                      userData: _vm.userData,
                      supportData: _vm.supportData,
                      hasFidelityCard: true,
                      isRegisterWithCard: _vm.isRegisterWithCard,
                      isFiscalCode: _vm.isFiscalCode,
                      registrationType: _vm.registrationType
                    },
                    on: {
                      "update:userData": function($event) {
                        _vm.userData = $event
                      },
                      "update:user-data": function($event) {
                        _vm.userData = $event
                      },
                      "update:supportData": function($event) {
                        _vm.supportData = $event
                      },
                      "update:support-data": function($event) {
                        _vm.supportData = $event
                      },
                      "update:isFiscalCode": function($event) {
                        _vm.isFiscalCode = $event
                      },
                      "update:is-fiscal-code": function($event) {
                        _vm.isFiscalCode = $event
                      },
                      back: function($event) {
                        return _vm.goToPreviousStep(true)
                      },
                      submit: function($event) {
                        _vm.isRegisterWithCard
                          ? _vm.findUser("form")
                          : _vm.goToNextStep()
                      }
                    }
                  })
                : _vm._e(),
              _vm.step === 2 && !_vm.isRegisterWithCard
                ? _c("UserAddress", {
                    attrs: {
                      userData: _vm.userData,
                      supportData: _vm.supportData
                    },
                    on: {
                      "update:userData": function($event) {
                        _vm.userData = $event
                      },
                      "update:user-data": function($event) {
                        _vm.userData = $event
                      },
                      "update:supportData": function($event) {
                        _vm.supportData = $event
                      },
                      "update:support-data": function($event) {
                        _vm.supportData = $event
                      }
                    }
                  })
                : _vm._e(),
              _vm.step === 2
                ? _c("UserEmailAddress", {
                    attrs: {
                      userData: _vm.userData,
                      supportData: _vm.supportData
                    },
                    on: {
                      "update:userData": function($event) {
                        _vm.userData = $event
                      },
                      "update:user-data": function($event) {
                        _vm.userData = $event
                      },
                      "update:supportData": function($event) {
                        _vm.supportData = $event
                      },
                      "update:support-data": function($event) {
                        _vm.supportData = $event
                      },
                      back: _vm.goToPreviousStep,
                      submit: _vm.registerUser
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }