var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-email-address-container mb-5" },
    [
      _c(
        "v-form",
        {
          ref: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
            }
          },
          model: {
            value: _vm.isValid,
            callback: function($$v) {
              _vm.isValid = $$v
            },
            expression: "isValid"
          }
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(`${_vm.$t("profile.contacts.email")} *`)
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      rules: _vm.emailRules,
                      placeholder: `${_vm.$t("profile.contacts.email")}`,
                      required: "",
                      outlined: "",
                      disabled: _vm.isFieldDisabled
                    },
                    model: {
                      value: _vm.userData.email,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "email", $$v)
                      },
                      expression: "userData.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(`${_vm.$t("profile.password")} *`)
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      color: "secondary",
                      placeholder: `${_vm.$t("profile.password")}`,
                      "append-icon": _vm.showPassword ? "$eye" : "$eyeOff",
                      type: _vm.showPassword ? "text" : "password",
                      rules: _vm.passwordRules,
                      required: "",
                      outlined: "",
                      disabled: _vm.isFieldDisabled
                    },
                    on: { "click:append": _vm.toggleShowPassword },
                    model: {
                      value: _vm.userData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.userData, "password", $$v)
                      },
                      expression: "userData.password"
                    }
                  })
                ],
                1
              ),
              _vm.hasCard
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("label", {
                        domProps: {
                          innerHTML: _vm._s(
                            `${_vm.$t("profile.contacts.secondaryEmail")}`
                          )
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          rules: _vm.secondaryEmailRules,
                          placeholder: `${_vm.$t(
                            "profile.contacts.secondaryEmailInfo"
                          )}`,
                          outlined: "",
                          disabled: _vm.isFieldDisabled
                        },
                        model: {
                          value: _vm.userData.contact.email2,
                          callback: function($$v) {
                            _vm.$set(_vm.userData.contact, "email2", $$v)
                          },
                          expression: "userData.contact.email2"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(`${_vm.$t("profile.contacts.iva")}`)
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      rules: _vm.ivaRules,
                      placeholder: "IT",
                      required:
                        !_vm.userData.person.vatCode &&
                        !_vm.userData.person.fiscalCode,
                      outlined: "",
                      disabled: _vm.isFieldDisabled
                    },
                    on: {
                      input: val =>
                        (_vm.userData.person.vatCode = _vm.userData.person.vatCode.toUpperCase())
                    },
                    model: {
                      value: _vm.userData.person.vatCode,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.person, "vatCode", $$v)
                      },
                      expression: "userData.person.vatCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c("label", {
                    domProps: {
                      innerHTML: _vm._s(
                        `${_vm.$t("profile.contacts.fiscalCodeIfnoIVA")}`
                      )
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mt-2",
                    attrs: {
                      rules: _vm.fiscalCodeRules,
                      placeholder: `${_vm.$t("profile.contacts.fiscalCode")}`,
                      required:
                        !_vm.userData.person.vatCode &&
                        !_vm.userData.person.fiscalCode,
                      outlined: "",
                      disabled: _vm.isFieldDisabled
                    },
                    on: {
                      input: val =>
                        (_vm.userData.person.fiscalCode = _vm.userData.person.fiscalCode.toUpperCase())
                    },
                    model: {
                      value: _vm.userData.person.fiscalCode,
                      callback: function($$v) {
                        _vm.$set(_vm.userData.person, "fiscalCode", $$v)
                      },
                      expression: "userData.person.fiscalCode"
                    }
                  })
                ],
                1
              ),
              _vm.hasCard
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("label", {
                        domProps: {
                          innerHTML: _vm._s(
                            `${_vm.$t("profile.contacts.codInt")} *`
                          )
                        }
                      }),
                      _c("v-text-field", {
                        staticClass: "mt-2",
                        attrs: {
                          placeholder: `${_vm.$t("profile.contacts.codInt")}`,
                          required: "",
                          rules: [_vm.requiredRules()],
                          outlined: "",
                          disabled: _vm.isFieldDisabled
                        },
                        on: {
                          input: val =>
                            (_vm.userData.codInt = _vm.userData.codInt.toUpperCase())
                        },
                        model: {
                          value: _vm.userData.codInt,
                          callback: function($$v) {
                            _vm.$set(_vm.userData, "codInt", $$v)
                          },
                          expression: "userData.codInt"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }